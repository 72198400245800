import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'public',
  initialState: {
    meta: {},
    products: [],
    subProducts: [],
    grievances: [],
    banks: [],
    flowBuilder: {},
    flowBuilderAllSteps: [],
    totalSteps: 0,
    icons: [],
    dataOfSteps: {},
    caseData: null,
    validateAPIcallSuccess: false,
    caseDataApiCallStatus: false,
    paymentRedirectUrl: null,
    caseDataOnId: {},
  },
  reducers: {
    fetchAllPublicProducts(state, action) { 
      state.products = action.payload;
    },
    fetchMetaPublic(state, action) { 
      state.meta = action.payload;
    },
    fetchStepsFromBackend(state, action) { 
      state.dataOfSteps = action.payload;
    },
    fetchCaseDataOnId(state, action) { 
      state.caseDataOnId = action.payload;
    },
    fetchBanks(state, action) { 
      state.banks = action.payload;
    },
    fetchFlowBuilderSteps(state, action) { 
      state.flowBuilder = action.payload;
    },
    fetchFlowBuilderAllSteps(state, action) { 
      state.flowBuilderAllSteps = action.payload;
    },
    fetchAllPublicSubProducts(state, action) { 
      state.subProducts = action.payload;
    },
    fetchAllPublicGrievance(state, action) { 
      state.grievances = action.payload;
    },
    fetchPublicStepsCount(state, action) { 
      state.totalSteps = action.payload;
    },
    fetchIconsOfPublicProducts(state, action) { 
      state.icons = action.payload;
    },
    fetchCaseDataOnSubmit(state, action) { 
      state.caseData = action.payload;
    },
    validateAPIcallSuccessOnSubmit(state, action) { 
      state.validateAPIcallSuccess = action.payload;
    },
    caseDataApiCallStatusAction(state, action) { 
      state.caseDataApiCallStatus = action.payload;
    },
    paymentRedirectUrl(state, action) { 
      state.paymentRedirectUrl = action.payload;
    },
  },
});

export const {fetchMetaPublic, fetchCaseDataOnId, paymentRedirectUrl, caseDataApiCallStatusAction, validateAPIcallSuccessOnSubmit ,fetchCaseDataOnSubmit, fetchAllPublicProducts, fetchStepsFromBackend, fetchBanks, fetchFlowBuilderAllSteps, fetchPublicStepsCount, fetchFlowBuilderSteps, fetchIconsOfPublicProducts, fetchAllPublicSubProducts, fetchAllPublicGrievance} = productSlice.actions;

export default productSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const bankContactSlice = createSlice({
  name: 'bankContact',
  initialState: {
    bankContacts: [],
    products: [],
    subProducts: [],
    editBankContacts: {},
    bankContactsCount: 0,
    addUpdateBankContactSuccess: false,
  },
  reducers: {
    fetchBankContacts(state, action) { 
      state.bankContacts = action.payload;
    },
    fetchBankContactsProducts(state, action) { 
      state.products = action.payload;
    },
    fetchBankContactsSubProducts(state, action) { 
      state.subProducts = action.payload;
    },
    fetchEditBankContacts(state, action) { 
      state.editBankContacts = action.payload;
    },
    bankContactsCount(state, action) { 
      state.bankContactsCount = action.payload ? action.payload: 0;
    },
    addUpdateBankContactSuccess(state, action) { 
      state.addUpdateBankContactSuccess = action.payload;
    },
  },
});

export const { fetchBankContacts, fetchBankContactsProducts, fetchBankContactsSubProducts, fetchEditBankContacts, bankContactsCount , addUpdateBankContactSuccess} = bankContactSlice.actions;

export default bankContactSlice.reducer;

import Routes from 'routes';

// project imports
//import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
//import RTLLayout from 'ui-component/RTLLayout';
//import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { I18nextProvider } from 'react-i18next';
import initializeI18n from 'utils/i18n';
import { useLanguage } from 'contexts/LanguageContext';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Snackbar from 'ui-component/extended/Snackbar';

const AppWeb = () => {
    const [i18nInstance, setI18nInstance] = useState<any>(null);
    const { language } = useLanguage();

    useEffect(() => {
        const initI18n = async () => {
            const i18n = await initializeI18n();
            setI18nInstance(i18n); // Set the initialized i18n instance
        };

        initI18n();
    }, [language]);

    if (!i18nInstance) {
        return (<Box maxWidth={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <div className='spinner'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </Box>);
    }
    return (<I18nextProvider i18n={i18nInstance}>
        <ThemeCustomization>
            <NavigationScroll>
                <><Routes />
                <Snackbar /></>
            </NavigationScroll>
        </ThemeCustomization>
    </I18nextProvider>);
};

export default AppWeb;

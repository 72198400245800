import { createSlice } from '@reduxjs/toolkit';

const apiSettingsSlice = createSlice({
  name: 'apiSettings',
  initialState: {
    smtp: {},
    translate: {},
    mailContent: {},
    otpCredential: {},
    addUpdateProductTypeSuccess: false,
  },
  reducers: {
    fetchSmtpSettings(state, action) { 
      state.smtp = action.payload;
    },
    fetchTranslateSettings(state, action) { 
      state.translate = action.payload;
    },
    fetchMailContent(state, action) { 
      state.mailContent = action.payload;
    },
    fetchOtpCredential(state, action) { 
      state.otpCredential = action.payload;
    },
    addUpdateProductTypeSuccess(state, action) { 
      state.addUpdateProductTypeSuccess = action.payload;
    }
  },
});

export const {fetchOtpCredential, fetchSmtpSettings, fetchTranslateSettings, fetchMailContent, addUpdateProductTypeSuccess} = apiSettingsSlice.actions;

export default apiSettingsSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const paymentSubsSlice = createSlice({
  name: 'paymentSubs',
  initialState: {
    subscriptions: [],
    count: 0,
    editSubs: {},
    addUpdateSubsSuccess: false,
  },
  reducers: {
    fetchPaymentSubs(state, action) { 
      state.subscriptions = action.payload;
    },
    fetchPaymentSubsCount(state, action) { 
      state.count = action.payload;
    },
    fetchEditSubs(state, action) { 
      state.editSubs = action.payload;
    },
    addUpdateSubsSuccess(state, action) { 
      state.addUpdateSubsSuccess = action.payload;
    }
  },
});

export const { fetchPaymentSubs, fetchPaymentSubsCount, fetchEditSubs , addUpdateSubsSuccess} = paymentSubsSlice.actions;

export default paymentSubsSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const accountsDetailsSlice = createSlice({
  name: 'accountsDetails',
  initialState: {
    credentials: [],
    companies: [],
    credentialsCounts: 0,
    companiesCounts: 0,
    editCredentials: {},
    editCompanies: {},
    addUpdateCredentialSuccess: false,
    addUpdateCompaniesSuccess: false,
    gst: null,
    pan: null,
    verifyGstStatus: false,
    verifyPanStatus: false,
  },
  reducers: {
    fetchAccountCredential(state, action) { 
      state.credentials = action.payload;
    },
    fetchVerifyGstStatus(state, action) { 
      state.verifyGstStatus = action.payload;
    },
    fetchVerifyPanStatus(state, action) { 
      state.verifyPanStatus = action.payload;
    },
    fetchAccountCompanies(state, action) { 
      state.companies = action.payload;
    },
    fetchCredentialsCount(state, action) { 
      state.credentialsCounts = action.payload ? action.payload: 0;
    },
    fetchCompaniesCount(state, action) { 
      state.companiesCounts = action.payload ? action.payload: 0;
    },
    fetchAddUpdateCredentialSuccess(state, action) { 
      state.addUpdateCredentialSuccess = action.payload;
    },
    fetchAddUpdateCompaniesSuccess(state, action) { 
      state.addUpdateCompaniesSuccess = action.payload;
    },
    fetchEditCredentials(state, action) { 
      state.editCredentials = action.payload;
    },
    fetchEditCompanies(state, action) { 
      state.editCompanies = action.payload;
    },
  },
});

export const {fetchVerifyGstStatus, fetchVerifyPanStatus, fetchAccountCredential, fetchAccountCompanies, fetchCompaniesCount, fetchCredentialsCount, 
  fetchEditCompanies, fetchEditCredentials, fetchAddUpdateCompaniesSuccess, fetchAddUpdateCredentialSuccess} = accountsDetailsSlice.actions;

export default accountsDetailsSlice.reducer;
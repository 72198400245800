import { createSlice } from '@reduxjs/toolkit';

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState: {
    codes: [],
    count: 0,
    editCode: {},
    addUpdatePromoCodeSuccess: false,
  },
  reducers: {
    fetchPromoCodes(state, action) { 
      state.codes = action.payload;
    },
    fetchPromoCodesCount(state, action) { 
      state.count = action.payload;
    },
    fetchEditPromoCodes(state, action) { 
      state.editCode = action.payload;
    },
    addUpdatePromoCodesSuccess(state, action) { 
      state.addUpdatePromoCodeSuccess = action.payload;
    }
  },
});

export const { fetchPromoCodes, fetchPromoCodesCount, fetchEditPromoCodes, addUpdatePromoCodesSuccess} = promoCodesSlice.actions;

export default promoCodesSlice.reducer;